import React, {useState} from "react";
import {Divider, Grid, MenuItem, Typography} from "@mui/material";
import {ImageOutlined, TableChartOutlined} from "@mui/icons-material";
import {CompareField, CompareTabs, CompareTab} from "./styles";
import {GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";
import {FC} from "react";
import {CaseTabContext} from "../../../pages/CaseDetails/useCaseControl.hook";
import {formatFullDate} from "../../../utils/DateUtils/dateUtils";
import {getWorkAuthStatusValue} from "../../../utils/CaseUtils/workAuthStatus";

import {
  getDisplayDocumentTitle,
  getDisplayDocumentTitleAboveImage,
  getDocumentImages
} from "../../../utils/CaseUtils/documentsDisplayUtil";
import DocumentImage from "../../DocumentImage";
import I9Form from "../I9Form";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index} = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
      >
        {value === index && <>{children}</>}
      </div>
  );
}

const ComparePanel: FC<{
  data: GenericJsonObject;
  context?: CaseTabContext;
  i9PdfFile?: Blob | undefined,
}> = ({data, i9PdfFile, context}) => {
  // eslint-disable-next-line
  const [selectedDocument, setSelectedDocument] = useState<number>(10);

  const [activeTab, setActiveTab] = useState(0);
  const handleSectionTabs = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };

  // eslint-disable-next-line
  const standaloneTransactionsSectionTwo: any = (data.standaloneTransactions as any).Section_2 || [];
  // eslint-disable-next-line
  const standaloneTransactionsSectionThree: any = (data.standaloneTransactions as any).Section_3 || [];
  // eslint-disable-next-line
  const sectionTwoData: any = (data.sectionTwoData as any).employee;
  // eslint-disable-next-line
  const sectionThreeData: any = data.sectionThreeData && (data.sectionThreeData as any).reverification.employee;


  // eslint-disable-next-line
  const repData : any = (data.sectionTwoData as any).employer_or_authorized_representatives;
  // eslint-disable-next-line
  const sectionTwoDocuments : {[key: string]:string}[] = repData.documents;
  // eslint-disable-next-line
  const sectionThreeDocuments : {[key: string]:string}[] = ((data.sectionThreeData as any)?.reverification as any)?.documents;

  const sectionThreeDocumentImages: { title: string, image: string }[]  = getDocumentImages(sectionThreeDocuments, standaloneTransactionsSectionThree);
  // const sectionTwoDocumentImages: { title: string, image: string }[]  = getDocumentImages(sectionTwoDocuments, standaloneTransactionsSectionTwo);

  // eslint-disable-next-line
  const sectionTwoDocumentImages: any = [];
  standaloneTransactionsSectionTwo &&
  // eslint-disable-next-line
  standaloneTransactionsSectionTwo.forEach((transaction: any) => {
      Object.keys(transaction.documents).sort().forEach((key) =>
        sectionTwoDocumentImages.push({
              title: key,
              image: transaction.documents[key].image,
          })
      );
  });

  const sectionCompareFields = [
    {
      label: "Last name (Family name)",
      value: context?.compareSection === 'COMPARE_SECTION_3' ? sectionThreeData.last_name : sectionTwoData.last_name,
    },
    {
      label: "First name (Given name)",
      value: context?.compareSection === 'COMPARE_SECTION_3' ? sectionThreeData.first_name : sectionTwoData.first_name,
    },
    {
      label: "Middle name",
      value: context?.compareSection === 'COMPARE_SECTION_3' ? sectionThreeData.middle_initial : sectionTwoData.middle_initial,
    },
    {
      label: "Citizen immigration status",
      value: sectionTwoData.work_auth ? getWorkAuthStatusValue(sectionTwoData.work_auth.status) : '',
    },
    ...(
        context?.compareSection === 'COMPARE_SECTION_3'?
            sectionThreeDocuments.flatMap((document) => ([
              {
                label: "Document title",
                value: document.document_title,
              },
              {
                label: "Document number",
                value: document.document_number,
              },
              {
                label: "Expiration date",
                value: formatFullDate(`${document.date_of_expiry}`),
              },
            ])) :
            sectionTwoDocuments.flatMap((document) => ([
              {
                label: "Document title",
                value: document.document_title,
              },
              {
                label: "Issuing authority ",
                value: document.issuing_authority,
              },
              {
                label: "Document number",
                value: document.document_number,
              },
              {
                label: "Expiration date",
                value: formatFullDate(`${document.date_of_expiry}`),
              },
            ]))
    )
  ];

  return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
                component={"h1"}
                sx={{fontSize: "18px", color: "#1A1A1A", fontWeight: "bold"}}
            >
              Compare {context?.compareSection === 'COMPARE_SECTION_3' ? 'Supplement B' : 'Section 2'} Documents
            </Typography>
            <p>
              The I-9 form {context?.compareSection === 'COMPARE_SECTION_3' ? 'Supplement B' : 'Section 2'} values and the
              submitted document image(s) are shown below for comparison.
            </p>
            <Divider sx={{marginBottom: "20px"}}/>
          </Grid>
          <Grid item xs={6}>
            <Typography
                sx={{fontSize: "14px", color: "#666666", marginBottom: "10px"}}
            >
              I-9 {context?.compareSection === 'COMPARE_SECTION_3' ? 'Supplement B' : 'Section 2'}
            </Typography>
            <CompareTabs value={activeTab} onChange={handleSectionTabs}>
              <CompareTab icon={<TableChartOutlined/>} id="0"/>
              <CompareTab icon={<ImageOutlined/>} id="1"/>
            </CompareTabs>
            <TabPanel value={activeTab} index={0}>
              {sectionCompareFields.map((item, index) => (
                  <CompareField
                      label={item.label}
                      defaultValue={item.value}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      key={`compare-field-${index}`}
                      fullWidth
                  />
              ))}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Grid item container xs={12}>
                <I9Form i9PdfFile={i9PdfFile}
                        showDocName={false}/>
              </Grid>
            </TabPanel>
          </Grid>
          <Grid item xs={6}>
            <Typography
                sx={{fontSize: "14px", color: "#666666", marginBottom: "10px"}}
            >
              Document Image
            </Typography>
            <CompareField
                variant="filled"
                select
                // eslint-disable-next-line
                onChange={(event: any) => {
                  setSelectedDocument(event.target.value);
                }}
            >
              {sectionThreeDocumentImages.length > 0
                  ? sectionThreeDocumentImages.map(
                      (item: { title: string, image: string }, index: number) => (
                          <MenuItem key={item.title} value={index}>
                            {getDisplayDocumentTitle(item.title)}
                          </MenuItem>
                      )
                  )
                  : sectionTwoDocumentImages.map(
                      (item: { title: string, image: string }, index: number) => (
                          <MenuItem key={item.title} value={index}>
                            {getDisplayDocumentTitle(item.title)}
                          </MenuItem>
                      )
                  )}
            </CompareField>
            {sectionThreeDocumentImages.length > 0
                ? sectionThreeDocumentImages.map((item: { title: string, image: string }, index: number) => (
                    <TabPanel key={item.image} value={selectedDocument} index={index}>
                      <DocumentImage key={item.image}
                                     imageName={getDisplayDocumentTitleAboveImage(sectionThreeDocumentImages, item.title)}
                                     imageScr={item.image}
                                     showImageName={false}/>
                    </TabPanel>
                ))
                : sectionTwoDocumentImages.map((item: { title: string, image: string }, index: number) => (
                    <TabPanel key={item.image} value={selectedDocument} index={index}>
                      <DocumentImage key={item.image}
                                     imageName={getDisplayDocumentTitleAboveImage(sectionThreeDocumentImages, item.title)}
                                     imageScr={item.image}
                                     showImageName={false}/>
                    </TabPanel>
                ))}
          </Grid>
        </Grid>
      </>
  );
};

export default ComparePanel;
